// i18n.js
import { createI18n } from 'vue-i18n';

const messages = {
  ar: require('./locales/ar/ar.json'),
};

const i18n = createI18n({
  locale: 'ar', // Default locale
  messages,
});

export default i18n;
